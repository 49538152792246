import React from 'react'
import "./footer.sass";

const Footer = () => {
  return (
    
  
    <footer id="footer" class="footer">
    <div class="copyright">
      {/* &copy; Copyright */}
       <strong><span> Western Regional Power Committee</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
    </div>
  </footer>

  // {/* <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> */}
   
  )
}

export default Footer